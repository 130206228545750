<!--
 * @Description: 商品菜单
 * @Autor: WangYuan
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-28 10:45:45
-->
<template>
  <SideBar :groups='groups'></SideBar>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  components: {
    SideBar,
  },
  
  data() {
    return {
      groups: [
        {
          label: "",
          list: [
            {
              name: "顶部导航",
              path: "/mall/control/list-tpl",
            },
            {
              name: "底部导航",
              path: "/mall/control/navigation-tpl",
            },
            {
              name: "风格主题",
              path: "/mall/control/search-tpl",
            },
          ]
        }
      ],
    };
  },


};
</script>